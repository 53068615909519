.seeAllContent,
.seeAllContent_hidden {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  z-index: 98;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.2s all;
}

.seeAllContent_hidden {
  height: 0;
  overflow: hidden;
}
.see_all_modal_container {
  background: #ddd9d7;
  padding: 3.125vh 1.5625vw;
  border-radius: 0.7813vw;
}

.select_title {
  font-family: Inter;
  font-weight: bold;
  font-size: 1.5625vw;
  color: #000;
  margin-bottom: 3.125dvh;
}

.share_platform_external_container_group {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.share_platform_external_container {
  margin-right: 2.344vw;
  text-decoration: none;
}
.share_platform_container {
  width: 3.91vw;
  height: 3.91vw;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.share_platform_external_text {
  font-size: 1.094vw;
  font-family: Inter;
  font-weight: bold;
  color: #000;
  margin-top: 0.391vw;
  margin-bottom: 2.344vh;
}
.modal_close_container {
  background-color: #ddd9d7;
  border: 0.16vw solid #000;
  display: flex;
  height: 9.38vh;
  justify-content: center;
  align-items: center;
  border-radius: 0.7813vw;
  width: 100%;
  cursor: pointer;
}
.modal_convert_container_share {
  margin-left: auto;
}
.modal_close_container {
  border: none;
  background: #000;
  width: 32.82vw;
  display: flex;
  margin: 0 auto;
  height: 9.38vh;
}
.close {
  color: #fff;
  font-size: 1.25vw;
  font-family: Inter;
  font-weight: bold;
}
@media screen and (max-width: 999px) {
  .seeAllContent {
    width: 100%;
    /* background: rgba(255, 255, 255, 0.8); */
    padding: 0;
    justify-content: flex-end;
  }
  .select_title {
    font-size: 20px;
    margin-bottom: 50px;
  }
  .share_platform_external_container {
    margin-right: 30px;
  }
  .share_platform_container {
    width: 60px;
    height: 60px;
  }
  .share_platform_external_text {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 20px;
  }
  .modal_close_container {
    width: 100%;
    border-radius: 10px;
    height: 70px;
  }
  .close {
    font-size: 16px;
  }
  .see_all_modal_container {
    width: 100%;
    padding: 20px 15px;
    border-radius: 10px;
  }
}
@media screen and (max-width: 540px) {
  .seeAllContent {
    width: 100%;
    /* background: rgba(255, 255, 255, 0.8); */
    padding: 0;
    justify-content: flex-end;
  }
  .select_title {
    font-size: calc(20 / 414 * 100vw);
    margin-bottom: calc(50 / 896 * 100vh);
  }
  .share_platform_external_container {
    margin-right: calc(30 / 414 * 100vw);
  }
  .share_platform_container {
    width: calc(60 / 414 * 100vw);
    height: calc(60 / 414 * 100vw);
  }
  .share_platform_external_text {
    font-size: calc(14 / 414 * 100vw);
    margin-top: calc(5 / 896 * 100vh);
    margin-bottom: calc(20 / 896 * 100vh);
  }
  .modal_close_container {
    width: 100%;
    border-radius: calc(10 / 414 * 100vw);
    height: calc(70 / 896 * 100vh);
  }
  .close {
    font-size: calc(16 / 414 * 100vw);
  }
  .see_all_modal_container {
    width: 100%;
    padding: calc(20 / 896 * 100vh) calc(15 / 414 * 100vw);
    border-radius: calc(10 / 414 * 100vw);
  }
}
