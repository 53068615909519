.container {
  background: transparent;
  height: fit-content;
}

.hero_sector_container {
  display: flex;
  flex-direction: column;
}

.sou,
.nmac,
.soundmac,
.soundmac_border_only,
.sub_text {
  color: rgba(217, 217, 217, 0.26);
  font-family: Inter;
  font-size: 15.625vw; /* 200 / 1280 * 100vw */
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  margin-left: auto;
  margin-top: -7.44vw; /* -95.04 / 1280 * 100vw */
  position: relative;
}

.nmac {
  margin-left: initial;
  position: initial;
}

.soundmac {
  color: #fff;
  font-size: 7.8125vw; /* 100 / 1280 * 100vw */
  margin-left: 6.96vw; /* 89.12 / 1280 * 100vw */
  /* z-index: 100; */
  position: relative;
}

.soundmac_border_only {
  font-size: 7.8125vw; /* 100 / 1280 * 100vw */
  font-weight: 900;
  -webkit-text-stroke: 0.15625vw rgba(217, 217, 217, 0.26); /* 2 / 1280 * 100vw */
  /* For Chrome and Safari */
  text-stroke: 1.25vw rgba(217, 217, 217, 0.26); /* 16 / 1280 * 100vw */
  /* For Firefox and other browsers */
  color: transparent;
  margin-left: 9.25vw; /* 117.92 / 1280 * 100vw */
}

.sub_text {
  color: #fff;
  font-size: 1.5625vw; /* 20 / 1280 * 100vw */
  position: initial;
  margin-top: -1.18vw; /* -15.04 / 1280 * 100vw */
  margin-left: 6.96vw; /* 89.12 / 1280 * 100vw */
}

.sub_text_2,
.sub_text_3 {
  color: #908b8b;
  font-family: Inter;
  font-size: 1.09375vw; /* 14 / 1280 * 100vw */
  font-style: normal;
  font-weight: 400;
  margin-left: 6.96vw; /* 89.12 / 1280 * 100vw */
  line-height: normal;
  width: 35.375vw; /* 452 / 1280 * 100vw */
  margin-top: 0.3vw; /* 3.84 / 1280 * 100vw */
}

.call_to_action {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
  font-family: Inter;
  font-size: 0.78125vw; /* 10 / 1280 * 100vw */
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 12.5vw; /* 160 / 1280 * 100vw */
  height: 2.734375vw; /* 35 / 1280 * 100vw */
  flex-shrink: 0;
  border-radius: 0.390625vw; /* 5 / 1280 * 100vw */
  background: #e74c3c;
  box-shadow: 0px 5vw 12.5vw 0px rgba(0, 0, 0, 0.06),
    0px 5vw 5vw 0px rgba(0, 0, 0, 0.25);
  margin-left: 6.96vw; /* 89.12 / 1280 * 100vw */
  margin-top: 2.11vw; /* 27.04 / 1280 * 100vw */
  padding: 0 1.1vw; /* 14.08 / 1280 * 100vw */
  position: relative;
  z-index: 90;
}

.call_to_action_text {
  margin-right: auto;
}

.lady {
  width: 36.5625vw; /* 468 / 1280 * 100vw */
  display: flex;
  margin-left: auto;
  margin-top: -38.125vw; /* -488 / 1280 * 100vw */
  margin-right: 4.515625vw; /* 57.92 / 1280 * 100vw */
}

.sub_text_3 {
  margin-top: -12.5vw; /* -160 / 1280 * 100vw */
}

.music_platforms_icon {
  width: 10.2265625vw; /* 130.98 / 1280 * 100vw */
  height: 3.90625vw; /* 50 / 1280 * 100vw */
}

.distribution_platforms_container {
  display: flex;
  align-items: center;
  margin-left: 6.96vw; /* 89.12 / 1280 * 100vw */
  flex-shrink: 0;
  margin-top: 2.26vw; /* 28.96 / 1280 * 100vw */
  margin-bottom: 3.7vw; /* 47.2 / 1280 * 100vw */
}

.plus_twenty {
  color: #fff;
  font-family: Inter;
  font-size: 1.09375vw; /* 14 / 1280 * 100vw */
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 0.625vw; /* 10.08 / 1280 * 100vw */
}
.metric_card_sub_container_group {
  display: flex;
  align-items: center;
}

.metrics_card {
  width: 88.671875vw; /* 1135 / 1280 * 100vw */
  height: 13.28125vw; /* 170 / 1280 * 100vw */
  flex-shrink: 0;
  border-radius: 1.5625vw; /* 20 / 1280 * 100vw */
  background: rgba(255, 255, 255, 0.82);
  box-shadow: 0px 5vw 12.5vw 0px rgba(0, 0, 0, 0.25);
  margin-left: 6.1640625vw; /* 79.04 / 1280 * 100vw */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: 50px;
}

.metrics_header {
  color: #210721;
  font-family: Inter;
  font-size: 1.875vw; /* 24 / 1280 * 100vw */
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.metrics_text {
  color: #908b8b;
  font-family: Inter;
  font-size: 1.09375vw; /* 14 / 1280 * 100vw */
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.separation_line {
  width: 0.15625vw; /* 2 / 1280 * 100vw */
  height: calc(118 / 640 * 100vh);
  background: #908b8b;
  margin: 0px 7.5625vw; /* 96.96 / 1280 * 100vw */
}

.sub_container {
  /* margin-left: 89.12px; */
  padding: 4.90625vw; /* 3.94 / 1280 * 100vw */
}

.heading {
  color: #fff;
  font-family: Inter;
  font-size: 1.875vw; /* 24 / 1280 * 100vw */
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 9.92vw; /* 127.04 / 1280 * 100vw */
}

.sub_heading {
  color: #908b8b;
  font-family: Inter;
  font-size: 1.09375vw; /* 14 / 1280 * 100vw */
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 0.625vw; /* 8 / 1280 * 100vw */
}

.soundmac_emphasis {
  color: #fff;
  font-family: Inter;
  font-size: 1.09375vw; /* 14 / 1280 * 100vw */
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.service_card_container {
  margin-top: 3.8125vw; /* 48.96 / 1280 * 100vw */
  display: flex;
  align-items: center;
  justify-content: center;
}

.service_card,
.middle_service_card {
  width: 28.5vw; /* 364.8 / 1280 * 100vw */
  height: 31.09375vw; /* 398 / 1280 * 100vw */
  flex-shrink: 0;
  border-radius: 1.5625vw; /* 20 / 1280 * 100vw */
  background: rgba(255, 255, 255, 0.82);
  box-shadow: 0px 5vw 5vw 0px rgba(0, 0, 0, 0.25);
  padding: 2.265625vw; /* 28.96 / 1280 * 100vw */
}

.middle_service_card {
  margin: 0 auto;
}

.service_card_heading {
  color: #000;
  font-family: Inter;
  font-size: 1.875vw; /* 24 / 1280 * 100vw */
  font-style: normal;
  font-weight: 700;
  margin-bottom: 1.061625vw; /* 16.96 / 1280 * 100vw */
  line-height: normal;
  width: 11.65vw; /* 149.12 / 1280 * 100vw */
}

.service_card_sub_text {
  color: #8e8e8e;
  font-family: Inter;
  font-size: 1.09375vw; /* 14 / 1280 * 100vw */
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 24.2578125vw; /* 308.92 / 1280 * 100vw */
}

.service_card_image,
.middle_service_card_image {
  width: 15.609375vw; /* 199.8 / 1280 * 100vw */
  height: 15.609375vw; /* 199.8 / 1280 * 100vw */
  flex-shrink: 0;
  display: flex;
  margin-top: 1.6vw; /* 20.48 / 1280 * 100vw */
  margin-left: auto;
}

.middle_service_card_image {
  width: 21.5625vw; /* 277 / 1280 * 100vw */
  height: 15.609375vw; /* 199.8 / 1280 * 100vw */
  flex-shrink: 0;
}

/* .interaction_container {
  display: flex;
  justify-content: center;
} */

.interaction_image {
  width: 29.0625vw; /* 371.8 / 1280 * 100vw */
  margin-top: -13.75vw; /* -176 / 1280 * 100vw */
  margin-left: 31.47vw; /* 403.04 / 1280 * 100vw */
}

.chat_container_1 {
  margin-top: 5.5625vw; /* 71.04 / 1280 * 100vw */
  margin-left: 11.43vw; /* 146.08 / 1280 * 100vw */
}

.chat_container_2 {
  position: absolute;
  margin-top: calc(
    -4.6875vw - 5.5625vw
  ); /* -60 / 1280 * 100vw - 71.04 / 1280 * 100vw */
  margin-left: 46.56vw; /* 597.44 / 1280 * 100vw */
}

.chat_container_4 {
  position: absolute;
  margin-top: calc(
    -4.6875vw - 5.5625vw
  ); /* -60 / 1280 * 100vw - 71.04 / 1280 * 100vw */
  margin-left: 46.56vw; /* 597.44 / 1280 * 100vw */
}

.chat_container_3 {
  position: absolute;
  margin-top: calc(
    4.6875vw + 9.1640625vw
  ); /* 60 / 1280 * 100vw + 117.28 / 1280 * 100vw */
  margin-left: 36.44vw; /* 466.24 / 1280 * 100vw */
}

.chat_container_4 {
  position: absolute;
  margin-top: 2.98vw; /* 38.08 / 1280 * 100vw */
  margin-left: 63.75vw; /* 815.04 / 1280 * 100vw */
}

.chat_container_5 {
  position: absolute;
  margin-top: 10.98vw; /* 38.08 / 1280 * 100vw */
  margin-left: 63.75vw; /* 815.04 / 1280 * 100vw */
}

.chat_box {
  width: 25.078125vw; /* 321 / 1280 * 100vw */
  height: 9.375vw; /* 120 / 1280 * 100vw */
  flex-shrink: 0;
  fill: #e4f3f4;
  filter: drop-shadow(0px 5vw 5vw rgba(0, 0, 0, 0.25));
}

.chat_box_2 {
  width: 22.1875vw; /* 284 / 1280 * 100vw */
  height: 5.46875vw; /* 70 / 1280 * 100vw */
  flex-shrink: 0;
  fill: #e4f3f4;
  filter: drop-shadow(0px 5vw 5vw rgba(0, 0, 0, 0.25));
}
.chat_box_3 {
  width: 22.1875vw; /* 284 / 1280 * 100vw */
  height: 5.46875vw; /* 70 / 1280 * 100vw */
  flex-shrink: 0;
  fill: #e4f3f4;
  filter: drop-shadow(0px 5vw 5vw rgba(0, 0, 0, 0.25));
}

.chat_text_1,
.chat_text_2,
.chat_text_3 {
  color: #000;
  font-family: Inter;
  font-size: 1.09375vw; /* 14 / 1280 * 100vw */
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 20.625vw; /* 264 / 1280 * 100vw */
  position: relative;
  margin-top: -7.91vw; /* -61.76 / 1280 * 100vw */
  margin-left: 1.7875vw; /* 22.88 / 1280 * 100vw */
}

.chat_text_2 {
  margin-top: -7.125vw; /* -52.8 / 1280 * 100vw */
}

.chat_text_3 {
  margin-top: -3.94vw; /* -44 / 1280 * 100vw */
  margin-left: 2.171875vw; /* 27.68 / 1280 * 100vw */
}

.read_receipt_container_1 {
  position: relative;
  width: 20.625vw; /* 264 / 1280 * 100vw */
  display: flex;
  margin-left: 17.5vw; /* 224 / 1280 * 100vw */
  margin-top: 1.71vw; /* 21.92 / 1280 * 100vw */
  align-items: center;
}

.time_1 {
  color: #908b8b;
  text-align: right;
  font-family: Inter;
  font-size: 0.78125vw; /* 10 / 1280 * 100vw */
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 0.3125vw; /* 4 / 1280 * 100vw */
}

.read_receipt {
  width: 1.015625vw; /* 16.25 / 1280 * 100vw */
  height: 0.46875vw; /* 7.5 / 1280 * 100vw */
  flex-shrink: 0;
  stroke-width: 1.5625vw; /* 20 / 1280 * 100vw */
  stroke: #4ee73c;
}

@media screen and (max-width: 999px) {
  .sou,
  .nmac,
  .soundmac,
  .soundmac_border_only,
  .sub_text {
    font-size: 75px;
    margin-top: -30px;
    position: relative;
  }
  .sou {
    margin-top: -50px;
  }

  .soundmac {
    font-size: 55px;
    margin-left: 10px;
  }

  .soundmac_border_only {
    font-size: 55px;
    -webkit-text-stroke: 2px rgba(217, 217, 217, 0.26); /* 2 / 1280 * 100vw */
    /* For Chrome and Safari */
    text-stroke: 2px rgba(217, 217, 217, 0.26); /* 16 / 1280 * 100vw */
    /* For Firefox and other browsers */
    margin-left: 30px;
  }

  .sub_text {
    color: #fff;
    font-size: 14px;
    position: initial;
    margin-top: 20px;
    margin-left: 10px;
    width: 60%;
  }

  .sub_text_2,
  .sub_text_3 {
    font-size: 12px;
    margin-left: 10px;
    width: 70%;
    margin-top: 20px;
  }

  .call_to_action {
    font-size: 12px;
    width: 200px;
    height: 35px;
    border-radius: 5px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06),
      0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin-left: 10px;
    margin-top: 17px;
    padding: 0px 10px;
  }

  .lady {
    width: 300px;
    margin-top: -315px;
    margin-right: 5px;
    margin-left: 280px;
  }

  .sub_text_3 {
    font-size: 14px;
    margin-top: -128px;
  }

  .music_platforms_icon {
    width: 89px;
    height: 34px;
  }

  .distribution_platforms_container {
    margin-left: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .plus_twenty {
    font-size: 14px;
    margin-left: 10px;
  }
  .metric_card_sub_container_group {
    display: flex;
    align-items: flex-start;
  }

  .metrics_card {
    width: 100%;
    height: 120px;
    flex-shrink: 0;
    border-radius: 20px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
    margin-left: 0px;
    padding: 5px 15px;
  }

  .metrics_header {
    font-size: 14px;
  }

  .metrics_text {
    font-size: 12px;
    margin-top: 3px;
  }

  .metric_card_sub_container {
    width: 40%;
  }
  .separation_line {
    width: 1px;
    height: 50px;
    margin: 0px 20px;
  }

  .sub_container {
    padding: 10px;
  }

  .heading {
    font-size: 16px;
    margin-top: 50px;
  }

  .sub_heading {
    font-size: 12px;
    margin-top: 3px;
  }

  .soundmac_emphasis {
    font-size: 14px;
  }

  .service_card_container {
    margin-top: 20px;
    flex-direction: column;
  }

  .service_card,
  .middle_service_card {
    width: 100%;
    height: fit-content;
    border-radius: 20px;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.25);
    padding: 30px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }

  .service_card_heading {
    font-size: 16px;
    margin-bottom: 15px;
    width: 100%;
  }

  .service_card_sub_text {
    font-size: 14px;
    width: 100%;
  }

  .service_card_image,
  .middle_service_card_image {
    width: 200px;
    height: 200px;
    margin-top: 30px;
    margin-left: auto;
  }

  .middle_service_card_image {
    width: 220px;
    height: 200px;
  }
  .interaction_container {
    display: flex;
    flex-direction: column;
  }

  .interaction_image {
    width: 380px;
    margin-top: 0px;
    margin-left: 0px;
  }

  .chat_container_1 {
    position: absolute;
    margin-top: 190px;
    margin-left: 0px;
  }

  .chat_container_2 {
    position: absolute;
    margin-top: 80px;
    margin-left: 80px;
  }

  .chat_container_3 {
    position: absolute;
    position: absolute;
    margin-top: 295px;
    margin-left: 60px;
  }

  .chat_container_4 {
    position: absolute;
    margin-top: 10px;
    margin-left: 0px;
  }

  .chat_container_5 {
    position: absolute;
    position: absolute;
    margin-top: 380px;
    margin-left: 10px;
  }

  .chat_box {
    width: 321px;
    height: 120px;
    fill: #e4f3f4;
    filter: drop-shadow(0px 5vw 5vw rgba(0, 0, 0, 0.25));
  }

  .chat_box_2 {
    width: 284px;
    height: 70px;
    flex-shrink: 0;
    filter: drop-shadow(0px 5vw 5vw rgba(0, 0, 0, 0.25));
  }
  .chat_box_3 {
    width: 284px;
    height: 70px;
    fill: #e4f3f4;
    filter: drop-shadow(0px 5vw 5vw rgba(0, 0, 0, 0.25));
  }

  .chat_text_1,
  .chat_text_2,
  .chat_text_3 {
    font-size: 12px;
    width: 264px;
    margin-top: -101.76px;
    margin-left: 30px;
  }

  .chat_text_2 {
    margin-top: -85px;
  }

  .chat_text_3 {
    margin-top: -50px;
    margin-left: 30px;
  }

  .read_receipt_container_1 {
    width: fit-content;
    margin-left: 220px;
    margin-top: 18px;
  }

  .time_1 {
    font-size: 10px;
    margin-right: 4px;
  }

  .read_receipt {
    width: 16.5px;
    height: 7.5px;
  }
}
@media screen and (max-width: 540px) {
  .sou,
  .nmac,
  .soundmac,
  .soundmac_border_only,
  .sub_text {
    font-size: calc(75 / 414 * 100vw);
    margin-top: calc(-30 / 414 * 100vw);
    position: relative;
  }
  .sou {
    margin-top: calc(-50 / 414 * 100vw);
  }

  .soundmac {
    font-size: calc(55 / 414 * 100vw);
    margin-left: calc(10 / 414 * 100vw);
  }

  .soundmac_border_only {
    font-size: calc(55 / 414 * 100vw);
    -webkit-text-stroke: calc(2 / 414 * 100vw) rgba(217, 217, 217, 0.26); /* 2 / 1280 * 100vw */
    /* For Chrome and Safari */
    text-stroke: calc(2 / 414 * 100vw) rgba(217, 217, 217, 0.26); /* 16 / 1280 * 100vw */
    /* For Firefox and other browsers */
    margin-left: calc(30 / 414 * 100vw);
  }

  .sub_text {
    color: #fff;
    font-size: calc(14 / 414 * 100vw);
    position: initial;
    margin-top: calc(20 / 414 * 100vw);
    margin-left: calc(10 / 414 * 100vw);
    width: 60%;
  }

  .sub_text_2,
  .sub_text_3 {
    font-size: calc(12 / 414 * 100vw);
    margin-left: calc(10 / 414 * 100vw);
    width: 70%;
    margin-top: calc(20 / 414 * 100vw);
  }

  .call_to_action {
    font-size: calc(12 / 414 * 100vw);
    width: calc(200 / 414 * 100vw);
    height: calc(35 / 414 * 100vw);
    border-radius: calc(5 / 414 * 100vw);
    box-shadow: 0px calc(4 / 414 * 100vw) calc(10 / 414 * 100vw) 0px
        rgba(0, 0, 0, 0.06),
      0px calc(4 / 414 * 100vw) calc(4 / 414 * 100vw) 0px rgba(0, 0, 0, 0.25);
    margin-left: calc(10 / 414 * 100vw);
    margin-top: calc(17 / 414 * 100vw);
    padding: 0px calc(10 / 414 * 100vw);
  }

  .lady {
    width: calc(300 / 414 * 100vw);
    margin-top: calc(-315 / 414 * 100vw);
    margin-right: calc(5 / 414 * 100vw);
    margin-left: calc(280 / 414 * 100vw);
  }

  .sub_text_3 {
    font-size: calc(14 / 414 * 100vw);
    margin-top: calc(-128 / 414 * 100vw);
  }

  .music_platforms_icon {
    width: calc(89 / 414 * 100vw);
    height: calc(34 / 414 * 100vw);
  }

  .distribution_platforms_container {
    margin-left: calc(10 / 414 * 100vw);
    margin-top: calc(20 / 414 * 100vw);
    margin-bottom: calc(20 / 414 * 100vw);
  }

  .plus_twenty {
    font-size: calc(14 / 414 * 100vw);
    margin-left: calc(10 / 414 * 100vw);
  }
  .metric_card_sub_container_group {
    display: flex;
    align-items: flex-start;
  }

  .metrics_card {
    width: 100%;
    height: calc(120 / 414 * 100vw);
    flex-shrink: 0;
    border-radius: calc(20 / 414 * 100vw);
    box-shadow: 0px calc(4 / 414 * 100vw) calc(10 / 414 * 100vw) 0px
      rgba(0, 0, 0, 0.25);
    margin-left: 0px;
    padding: calc(5 / 414 * 100vw) calc(15 / 414 * 100vw);
  }

  .metrics_header {
    font-size: calc(14 / 414 * 100vw);
  }

  .metrics_text {
    font-size: calc(12 / 414 * 100vw);
    margin-top: calc(3 / 414 * 100vw);
  }

  .metric_card_sub_container {
    width: 40%;
  }
  .separation_line {
    width: calc(1 / 414 * 100vw);
    height: calc(50 / 414 * 100vw);
    margin: 0px calc(20 / 414 * 100vw);
  }

  .sub_container {
    padding: calc(10 / 414 * 100vw);
  }

  .heading {
    font-size: calc(16 / 414 * 100vw);
    margin-top: calc(50 / 414 * 100vw);
  }

  .sub_heading {
    font-size: calc(12 / 414 * 100vw);
    margin-top: calc(3 / 414 * 100vw);
  }

  .soundmac_emphasis {
    font-size: calc(14 / 414 * 100vw);
  }

  .service_card_container {
    margin-top: calc(20 / 414 * 100vw);
    flex-direction: column;
  }

  .service_card,
  .middle_service_card {
    width: 100%;
    height: fit-content;
    border-radius: calc(20 / 414 * 100vw);
    box-shadow: 0px calc(10 / 414 * 100vw) calc(10 / 414 * 100vw) 0px
      rgba(0, 0, 0, 0.25);
    padding: calc(30 / 414 * 100vw);
    margin-top: calc(20 / 414 * 100vw);
    display: flex;
    flex-direction: column;
  }

  .service_card_heading {
    font-size: calc(16 / 414 * 100vw);
    margin-bottom: calc(15 / 414 * 100vw);
    width: 100%;
  }

  .service_card_sub_text {
    font-size: calc(14 / 414 * 100vw);
    width: 100%;
  }

  .service_card_image,
  .middle_service_card_image {
    width: calc(200 / 414 * 100vw);
    height: calc(200 / 414 * 100vw);
    margin-top: calc(30 / 414 * 100vw);
    margin-left: auto;
  }

  .middle_service_card_image {
    width: calc(220 / 414 * 100vw);
    height: calc(200 / 414 * 100vw);
  }
  .interaction_container {
    display: flex;
    flex-direction: column;
  }

  .interaction_image {
    width: calc(380 / 414 * 100vw);
    margin-top: 0px;
    margin-left: 0px;
  }

  .chat_container_1 {
    position: absolute;
    margin-top: calc(190 / 414 * 100vw);
    margin-left: 0px;
  }

  .chat_container_2 {
    position: absolute;
    margin-top: calc(80 / 414 * 100vw);
    margin-left: calc(80 / 414 * 100vw);
  }

  .chat_container_3 {
    position: absolute;
    position: absolute;
    margin-top: calc(295 / 414 * 100vw);
    margin-left: calc(60 / 414 * 100vw);
  }

  .chat_container_4 {
    position: absolute;
    margin-top: calc(10 / 414 * 100vw);
    margin-left: 0px;
  }

  .chat_container_5 {
    position: absolute;
    position: absolute;
    margin-top: calc(380 / 414 * 100vw);
    margin-left: calc(10 / 414 * 100vw);
  }

  .chat_box {
    width: calc(321 / 414 * 100vw);
    height: calc(121 / 414 * 100vw);
    fill: #e4f3f4;
    filter: drop-shadow(0px 5vw 5vw rgba(0, 0, 0, 0.25));
  }

  .chat_box_2 {
    width: calc(284 / 414 * 100vw);
    height: calc(70 / 414 * 100vw);
    flex-shrink: 0;
    filter: drop-shadow(0px 5vw 5vw rgba(0, 0, 0, 0.25));
  }
  .chat_box_3 {
    width: calc(284 / 414 * 100vw);
    height: calc(70 / 414 * 100vw);
    fill: #e4f3f4;
    filter: drop-shadow(0px 5vw 5vw rgba(0, 0, 0, 0.25));
  }

  .chat_text_1,
  .chat_text_2,
  .chat_text_3 {
    font-size: calc(12 / 414 * 100vw);
    width: calc(264 / 414 * 100vw);
    margin-top: calc(-101.76 / 414 * 100vw);
    margin-left: calc(30 / 414 * 100vw);
  }

  .chat_text_2 {
    margin-top: calc(-85 / 414 * 100vw);
  }

  .chat_text_3 {
    margin-top: calc(-50 / 414 * 100vw);
    margin-left: calc(30 / 414 * 100vw);
  }

  .read_receipt_container_1 {
    width: fit-content;
    margin-left: calc(220 / 414 * 100vw);
    margin-top: calc(18 / 414 * 100vw);
  }

  .time_1 {
    font-size: calc(10 / 414 * 100vw);
    margin-right: calc(4 / 414 * 100vw);
  }

  .read_receipt {
    width: calc(16.5 / 414 * 100vw);
    height: calc(7.5 / 414 * 100vw);
  }
}
