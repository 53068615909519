.icon {
  width: 1.95vw;
  height: 1.95vw;
}

@media screen and (max-width: 999px) {
  .icon {
    width: 20px;
    height: 20px;
  }
}

@media screen and (max-width: 540px) {
  .icon {
    width: calc(20 / 414 * 100vw);
    height: calc(20 / 414 * 100v2);
  }
}
