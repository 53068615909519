.container {
  width: 100%;
  height: 100%;
  padding: calc(37 / 640 * 100vh) calc(37 / 1280 * 100vw) 0px
    calc(37 / 1280 * 100vw);
}

.sub_container {
  margin-top: calc(10 / 640 * 100vh);
  display: flex;
  align-items: center;
}

.image_box,
.image_container,
.audio_box {
  width: calc(264 / 1280 * 100vw);
  height: calc(212 / 640 * 100vh);
  flex-shrink: 0;
  display: flex;
  border-radius: calc(10 / 1280 * 100vw);
  border: calc(1 / 1280 * 100vw) dashed #d9d9d9;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.829);
  position: relative;
}
.audio_box {
  width: calc(264 / 1280 * 100vw);
  /* height: calc(137 / 640 * 100vh); */
  flex-shrink: 0;
  /* margin-top: calc(30 / 640 * 100vh); */
}
.image_container {
  padding: calc(10 / 640 * 100vh) calc(10 / 1280 * 100vw);
  background: transparent;
  position: absolute;
  z-index: -1;
}
.image {
  width: calc(192 / 1280 * 100vw);
  height: calc(192 / 640 * 100vh);
  border-radius: calc(10 / 1280 * 100vw);
  object-fit: cover;
}
.image_instructions,
.audio_instructions {
  color: #fff;
  font-family: Inter;
  font-size: calc(10 / 1280 * 100vw);
  font-style: normal;
  font-weight: 700;
  line-height: calc(28 / 640 * 100vh);
}

/* .audio_instructions {
  color: #908b8b;
} */
.select_image_btn {
  width: calc(109 / 1280 * 100vw);
  height: calc(35 / 640 * 100vh);
  flex-shrink: 0;
  display: flex;
  border-radius: calc(5 / 1280 * 100vw);
  background: #4169e1;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06),
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: Inter;
  font-size: calc(10 / 1280 * 100vw);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: calc(18 / 640 * 100vh);
  transition: all 0.2s;
  cursor: pointer;
}
.select_image_btn:hover,
.upload_btn:hover {
  background: var(--blueBtnHover);
}

.vertical_line {
  margin: 0px calc(13 / 1280 * 100vw);
  width: calc(1 / 1280 * 100vw);
  height: calc(378 / 640 * 100vh);
  background: #d9d9d9;
}
.input_container,
.small_input_container,
.second_small_input_container,
.last_input_container,
.small_input_container_2,
.second_small_input_container_2 {
  width: calc(264 / 1280 * 100vw);
  height: calc(32 / 640 * 100vh);
  flex-shrink: 0;
  border-radius: calc(5 / 1280 * 100vw);
  background: #f8f8f8;
  display: flex;
  align-items: center;
  padding: 0px calc(13 / 1280 * 100vw);
  margin-bottom: calc(28 / 640 * 100vh);
}
.last_input_container {
  margin-bottom: 0;
}
.small_input_container,
.second_small_input_container,
.small_input_container_2,
.second_small_input_container_2 {
  width: calc(128 / 1280 * 100vw);
}
/* .second_small_input_container {
  margin-left: auto;
} */
.small_input_container_2,
.second_small_input_container_2 {
  margin-bottom: 0;
}
/* .second_small_input_container_2 {
  margin-left: auto;
} */
.small_group {
  display: flex;
}
.options_container {
  margin-top: calc(35 / 640 * 100vh);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
  position: absolute;
  background: #f8f8f8;
  max-height: calc(165 / 640 * 100vh);
  overflow-y: auto;
  border-radius: calc(5 / 1280 * 100vw);
}
.options_sub_container {
  padding: calc(5 / 640 * 100vh) calc(5 / 1280 * 100vw);
}

.input_text,
.radio_text,
.input_text::placeholder,
.selected_option,
.option_text {
  border: none;
  background: transparent;
  outline: none;
  color: #908b8b;
  font-family: Inter;
  font-size: calc(12 / 1280 * 100vw);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 100%;
}
.option_text {
  padding: calc(5 / 640 * 100vh) calc(5 / 1280 * 100vw);
  cursor: pointer;
  transition: all 0.2s;
  border-radius: calc(2 / 1280 * 100vw);
}
.option_text:hover {
  background: #4169e1;
  color: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
}

.input_text {
  color: #4169e1;
}

.radio_text {
  width: fit-content;
  margin-left: calc(5 / 1280 * 100vw);
}
.radio {
  margin-right: calc(5 / 1280 * 100vw);
}
.question,
.question_2 {
  color: #228699;
  font-family: Inter;
  font-size: calc(10 / 1280 * 100vw);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: calc(5 / 640 * 100vh);
  width: 100%;
}
.question_2 {
  margin-bottom: calc(12 / 640 * 100vh);
  margin-top: calc(38 / 640 * 100vh);
}
.radio_container {
  width: calc(15 / 1280 * 100vw);
  height: calc(15 / 1280 * 100vw);
  flex-shrink: 0;
  border-radius: 100%;
  border: calc(1 / 1280 * 100vw) solid #908b8b;
  display: flex;
  align-items: center;
  justify-content: center;
}
.checked {
  width: calc(10 / 1280 * 100vw);
  height: calc(10 / 1280 * 100vw);
  border-radius: 100%;
  background: #4169e1;
}

.radio_label_group {
  margin-right: calc(20 / 1280 * 100vw);
  display: flex;
}
.text_area_container,
.text_area_container::placeholder {
  width: calc(331 / 1280 * 100vw);
  height: calc(378 / 640 * 100vh);
  border: none;
  background: transparent;
  outline: none;
  color: #908b8b;
  background: #f8f8f8;
  font-family: Inter;
  font-size: calc(12 / 1280 * 100vw);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  resize: none;
  overflow-y: auto;
  border-radius: calc(5 / 1280 * 100vw);
  padding: calc(13 / 640 * 100vh) calc(13 / 1280 * 100vw);
}
.horizontal_line {
  width: calc(1202 / 1280 * 100vw);
  height: calc(1 / 1280 * 100vw);
  margin: calc(10 / 640 * 100vh) 0;
  background: #d9d9d9;
}
.conclude_session {
  display: flex;
  align-items: flex-start;
}
.conclusion_text {
  color: #908b8b;
  font-family: Inter;
  font-size: calc(12 / 1280 * 100vw);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: calc(875 / 1280 * 100vw);
  margin-left: calc(5 / 1280 * 100vw);
  margin-right: auto;
}
.conclusion_text_emphasis {
  color: #4169e1;
  font-weight: 700;
  text-decoration: underline;
}
.upload_btn {
  width: fit-content;
  height: calc(35 / 640 * 100vh);
  flex-shrink: 0;
  display: flex;
  border-radius: calc(35 / 1280 * 100vw);
  background: #4169e1;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06),
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: Inter;
  font-size: calc(10 / 1280 * 100vw);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  padding: calc(5 / 640 * 100vh) calc(10 / 1280 * 100vw);
}

.loadingProgress {
  position: absolute;
}
.conclude_session_check_group {
  display: flex;
  align-items: flex-start;
  margin-right: auto;
}
@media screen and (max-width: 999px) {
  .container {
    padding: 10px;
  }

  .sub_container {
    margin-top: 10px;
    flex-direction: column;
    width: 100%;
  }
  .file_section {
    width: 100%;
  }

  .image_box,
  .image_container,
  .audio_box {
    width: 100%;
    height: 212px;
    border-radius: 10px;
    border: 1px dashed #d9d9d9;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
  }
  .audio_box {
    margin-top: 10px;
    /* height: 137px; */
  }
  .image_container {
    padding: 10px;
  }
  .image {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  .image_instructions,
  .audio_instructions {
    font-size: 10px;
    line-height: 28px;
  }

  .select_image_btn {
    width: 109px;
    height: 35px;
    border-radius: 5px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06),
      0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-size: 10px;
    margin-top: 18px;
  }

  .vertical_line {
    margin: 13px 0px;
    width: 100%;
    height: 1px;
  }
  .middle_section {
    width: 100%;
  }
  .input_container,
  .small_input_container,
  .second_small_input_container,
  .last_input_container,
  .small_input_container_2,
  .second_small_input_container_2 {
    width: 100%;
    height: 52px;
    border-radius: 5px;
    padding: 0px 13px;
    margin-bottom: 10px;
    margin-right: 0;
  }
  .last_input_container {
    margin-bottom: 0;
  }
  .small_input_container,
  .second_small_input_container,
  .small_input_container_2,
  .second_small_input_container_2 {
    width: 48%;
  }
  /* .second_small_input_container {
    margin-left: auto;
  } */
  .small_input_container_2,
  .second_small_input_container_2 {
    margin-bottom: 0;
  }
  /* .second_small_input_container_2 {
    margin-left: auto;
  } */
  .small_group {
    display: flex;
  }
  .options_container {
    margin-top: 35px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
    max-height: 165px;
    overflow-y: auto;
    border-radius: 5px;
  }
  .options_sub_container {
    padding: 5px;
  }

  .input_text,
  .radio_text,
  .input_text::placeholder,
  .selected_option,
  .option_text {
    font-size: 12px;
    width: 100%;
  }
  .option_text {
    padding: 5px;
    border-radius: 2px;
  }

  .radio_text {
    margin-left: 5px;
  }
  .radio {
    margin-right: 5px;
  }
  .question,
  .question_2 {
    font-size: 14px;
    margin: 5px 0;
    width: 100%;
  }
  .radio_container {
    width: 15px;
    height: 15px;
    border-radius: 100%;
  }
  .checked {
    width: 10px;
    height: 10px;
    border-radius: 100%;
  }

  .radio_label_group {
    margin-right: 20px;
  }
  .lyrics_section {
    width: 100%;
  }
  .text_area_container,
  .text_area_container::placeholder {
    width: 100%;
    height: 378px;
    font-size: 12px;
    border-radius: 5px;
    padding: 13px;
  }
  .horizontal_line {
    width: 100%;
    height: 1px;
    margin: 10px 0;
  }
  .conclude_session {
    display: flex;
    flex-direction: column;
  }
  .conclusion_text {
    font-size: 12px;
    width: 100%;
    margin-left: 5px;
    margin-right: 0;
  }
  .upload_btn {
    width: 100%;
    height: 55px;
    display: flex;
    border-radius: 5px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06),
      0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-size: 14px;
    margin-top: 10px;
    padding: 5px 10px;
  }

  .loadingProgress {
    position: absolute;
    background: green;
  }
}

@media screen and (max-width: 540px) {
  .container {
    padding: calc(10 / 896 * 100vh) calc(10 / 414 * 100vw);
  }

  .sub_container {
    margin-top: calc(10 / 896 * 100vh);
    flex-direction: column;
    width: 100%;
  }
  .file_section {
    width: 100%;
  }

  .image_box,
  .image_container,
  .audio_box {
    width: 100%;
    height: calc(212 / 896 * 100vh);
    border-radius: calc(10 / 414 * 100vw);
    border: calc(1 / 414 * 100vw) dashed #d9d9d9;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
  }
  .audio_box {
    margin-top: calc(10 / 896 * 100vh);
    /* height: calc(137 / 896 * 100vh); */
  }
  .image_container {
    padding: calc(10 / 896 * 100vh) calc(10 / 414 * 100vw);
  }
  .image {
    width: 100%;
    height: 100%;
    border-radius: calc(10 / 414 * 100vw);
  }
  .image_instructions,
  .audio_instructions {
    font-size: calc(10 / 414 * 100vw);
    line-height: calc(28 / 896 * 100vh);
  }

  .select_image_btn {
    width: calc(109 / 414 * 100vw);
    height: calc(35 / 896 * 100vh);
    border-radius: calc(5 / 414 * 100vw);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06),
      0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-size: calc(10 / 414 * 100vw);
    margin-top: calc(18 / 896 * 100vh);
  }

  .vertical_line {
    margin: calc(13 / 896 * 100vh) 0px;
    width: 100%;
    height: 1px;
  }
  .middle_section {
    width: 100%;
  }
  .input_container,
  .small_input_container,
  .second_small_input_container,
  .last_input_container,
  .small_input_container_2,
  .second_small_input_container_2 {
    width: 100%;
    height: calc(52 / 896 * 100vh);
    border-radius: calc(5 / 414 * 100vw);
    padding: 0px calc(13 / 414 * 100vw);
    margin-bottom: calc(35 / 896 * 100vh);
    margin-right: 0;
  }
  .last_input_container {
    margin-bottom: 0;
  }
  .small_input_container,
  .second_small_input_container,
  .small_input_container_2,
  .second_small_input_container_2 {
    width: 48%;
  }
  /* .second_small_input_container {
    margin-left: auto;
  } */
  .small_input_container_2,
  .second_small_input_container_2 {
    margin-bottom: 0;
  }
  /* .second_small_input_container_2 {
    margin-left: auto;
  } */
  .small_group {
    display: flex;
  }
  .options_container {
    margin-top: calc(35 / 896 * 100vh);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
    max-height: calc(165 / 896 * 100vh);
    overflow-y: auto;
    width: 70%;
    border-radius: calc(5 / 414 * 100vw);
  }
  .options_sub_container {
    padding: calc(5 / 896 * 100vh) calc(5 / 414 * 100vw);
  }

  .input_text,
  .radio_text,
  .input_text::placeholder,
  .selected_option,
  .option_text {
    font-size: calc(12 / 414 * 100vw);
    width: 100%;
  }
  .option_text {
    padding: calc(5 / 896 * 100vh) calc(5 / 414 * 100vw);
    border-radius: calc(2 / 414 * 100vw);
  }

  .radio_text {
    margin-left: calc(2 / 414 * 100vw);
  }
  .radio {
    margin-right: calc(2 / 414 * 100vw);
  }
  .question,
  .question_2 {
    font-size: calc(14 / 414 * 100vw);
    margin: calc(5 / 896 * 100vh) 0;
    width: 100%;
  }
  .radio_container {
    width: calc(15 / 414 * 100vw);
    height: calc(15 / 414 * 100vw);
    border-radius: 100%;
  }
  .checked {
    width: calc(10 / 414 * 100vw);
    height: calc(10 / 414 * 100vw);
    border-radius: 100%;
  }

  .radio_label_group {
    margin-right: calc(20 / 414 * 100vw);
  }
  .lyrics_section {
    width: 100%;
  }
  .text_area_container,
  .text_area_container::placeholder {
    width: 100%;
    height: calc(378 / 896 * 100vh);
    font-size: calc(12 / 414 * 100vw);
    border-radius: calc(5 / 414 * 100vw);
    padding: calc(13 / 896 * 100vh) calc(13 / 414 * 100vw);
  }
  .horizontal_line {
    width: 100%;
    height: calc(1 / 896 * 100vh);
    margin: calc(10 / 896 * 100vh) 0;
  }
  .conclude_session {
    display: flex;
    flex-direction: column;
  }
  .conclusion_text {
    font-size: calc(12 / 414 * 100vw);
    width: 100%;
    margin-left: calc(5 / 414 * 100vw);
    margin-right: 0;
  }
  .upload_btn {
    width: 100%;
    height: calc(55 / 896 * 100vh);
    display: flex;
    border-radius: 5px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06),
      0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-size: calc(14 / 414 * 100vw);
    margin-top: calc(10 / 896 * 100vh);
    padding: calc(5 / 896 * 100vh) calc(10 / 414 * 100vw);
  }

  .loadingProgress {
    position: absolute;
  }
}
