.ql-editor.ql-blank::before {
    color: white; /* Change placeholder text color */
    font-style: italic; /* Make placeholder text italic */
    font-size: 1rem; /* Adjust font size */
    opacity: 0.6; /* Adjust opacity */
  }

  .ql-editor{
    transition: height 0.3s ease-in;
  }
  .ql-container:focus-within .ql-editor{
    min-height: 200px;
    }

  .ql-snow .ql-picker {
    color: #f8f8f8;
  }
  .ql-snow .ql-stroke {
    stroke: #f8f8f8;
  }
  .ql-container.ql-snow{
    border: none;
  }
  .ql-container.ql-snow {
    border: 1px solid transparent; /* Default border (transparent) */
    /* border-radius: 0.5rem; Rounded corners */
    transition: border-color 0.6s ease; /* Smooth transition for the border color */

  }
  
  /* Apply a white border when focused */
  .ql-container.ql-snow:focus-within {
    border-color: #f8f8f8; /* White border on focus */
  }
  .ql-snow .ql-picker-options{
    background-color: black;
  }