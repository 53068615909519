.container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.loader {
  width: 5.46875vw; /* 70px to vw */
  height: 5.46875vw; /* 70px to vw */
  border-style: solid;
  border-top-color: #fff;
  border-right-color: #fff;
  border-left-color: transparent;
  border-bottom-color: transparent;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotate 3s ease-in-out infinite;
  transform: rotate(-200deg);
}

@keyframes rotate {
  0% {
    border-width: 0.78125vw; /* 10px to vw */
  }
  25% {
    border-width: 0.234375vw; /* 3px to vw */
  }
  50% {
    transform: rotate(115deg);
    border-width: 0.78125vw; /* 10px to vw */
  }
  75% {
    border-width: 0.234375vw; /* 3px to vw */
  }
  100% {
    border-width: 0.78125vw; /* 10px to vw */
  }
}
