@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  /* background-color: #111; */
  font-family: "Raleway", "sans-serif";
}
@layer utilities {
  .grad {
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.2) 100%
      ),
      linear-gradient(
        94deg,
        rgba(34, 134, 153, 0.42) 6.6%,
        rgba(0, 255, 255, 0.4) 33.23%,
        rgba(13, 168, 234, 0.62) 51.22%,
        rgba(13, 114, 234, 0.8) 70.66%,
        rgba(34, 134, 153, 0.61) 87.89%
      ),
      #000;
  }
  .transparent-text {
    color: transparent;
    -webkit-text-stroke: 0.15625vw rgba(217, 217, 217, 0.26);
  }
  .stop-scrolling {
    @apply h-full overflow-hidden;
  }
  .img {
    /* background-image: url("./assets/images/hero_bg.webp"); */
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    /* background: transparent; */
  }
}
