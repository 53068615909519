@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
:root {
  --btnHoverTransition: all 0.3s;
  --redBtnHover: #e54207;
  --whiteBtnHover: #e6e3e3;
  --blueBtnHover: #193ba4;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Hide the number input spin buttons */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0; /* Remove the default margin */
}
input[type="date"]:before {
  color: #908b8b;
  content: attr(placeholder) !important;
  font-size: 14px;
  width: 100%;
}
input[type="date"]:focus:before {
  content: "" !important;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
  .grad {
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.2) 100%
      ),
      linear-gradient(
        94deg,
        rgba(34, 134, 153, 0.42) 6.6%,
        rgba(0, 255, 255, 0.4) 33.23%,
        rgba(13, 168, 234, 0.62) 51.22%,
        rgba(13, 114, 234, 0.8) 70.66%,
        rgba(34, 134, 153, 0.61) 87.89%
      ),
      #000;
  }
  .transparent-text {
    color: transparent;
    -webkit-text-stroke: 0.15625vw rgba(217, 217, 217, 0.26);
  }
  .stop-scrolling {
    @apply h-full overflow-hidden;
  }
  .img {
    /* background-image: url("./assets/images/hero_bg.webp"); */
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    /* background: transparent; */
  }
  .text_color{
    color: #e54207 !important;
  
  }
  @media (max-width:768px) {
    .full{
      max-width: 90% !important;
    }
  }
  .root {
    max-width: 100%;
    height: 100dvh;
    text-align: center;
  }
  
  .logo {
    height: 6em;
    padding: 1.5em;
  }
  
  
  @media (prefers-reduced-motion: no-preference) {
    div .logo {
      animation: logo-spin infinite 1s linear;
    }
  }
  
  @keyframes logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}