.icon {
  width: 1.8vw;
  height: 1.8vw;
  flex-shrink: 0;
}
@media screen and (max-width: 999px) {
  .icon {
    width: 23px;
    height: 23px;
    flex-shrink: 0;
  }
}

@media screen and (max-width: 540px) {
  .icon {
    width: 5.6vw;
    height: 5.6vw;
    flex-shrink: 0;
  }
}
