.container {
  padding: calc(12.8 / 1280 * 100vw) calc(26.176 / 1280 * 100vw);
  height: calc(80 / 1280 * 100vw);
  background: #fff;
  box-shadow: 0vw 0.3125vw 0.3125vw 0vw rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  position: relative;
  z-index: 100;
  padding: 0vw calc(20 / 1280 * 100vw);
}
.logo {
  width: calc(43.2 / 1280 * 100vw);
  height: calc(41.6 / 1280 * 100vw);
  margin-right: calc(26.176 / 1280 * 100vw);
}
.text {
  font-family: Inter;
  font-size: calc(16 / 1280 * 100vw);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.user_names {
  color: #0ff;
  font-family: Inter;
  font-weight: 700;
  width: calc(130 / 1280 * 100vw);
}

.account_type {
  color: #908b8b;
  font-size: calc(10 / 1280 * 100vw);
  margin-top: calc(5 / 1280 * 100vw);
}

.navigation_btn_container {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.nav_icon {
  width: calc(20 / 1280 * 100vw);
  height: calc(20 / 1280 * 100vw);
  cursor: pointer;
}

.nav_icon_container {
  margin-right: calc(20 / 1280 * 100vw);
  display: flex;
  flex-direction: column;
}

.small_dot {
  border-radius: 100%;
  width: calc(6 / 1280 * 100vw);
  height: calc(6 / 1280 * 100vw);
  /* background: #FF4500; */
  margin: 0vw auto;
  margin-top: calc(3 / 1280 * 100vw);
}

.bell_container {
  margin-left: calc(50 / 1280 * 100vw);
}
@media screen and (max-width: 999px) {
  .container {
    padding: 12.8px 10px;
    height: 80px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 0vw 10px;
    background: black;
    color: #fff;
  }

  .text {
    font-size: 14px;
  }

  .account_type {
    font-size: 10px;
  }

  .nav_icon {
    width: 20px;
    height: 20px;
  }

  .nav_icon_container {
    margin-right: 20px;
  }

  .small_dot {
    width: 6px;
    height: 6px;
    margin-top: 3px;
  }

  .bell_container {
    margin-left: 10px;
  }
  .logo {
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 540px) {
  .container {
    height: calc(80 / 414 * 100vw);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 0vw calc(10 / 414 * 100vw);
    background: black;
    color: #fff;
  }

  .text {
    font-size: calc(14 / 414 * 100vw);
    width: calc(176 / 414 * 100vw);
  }

  .account_type {
    font-size: calc(10 / 414 * 100vw);
  }

  .nav_icon {
    width: calc(20 / 414 * 100vw);
    height: calc(20 / 414 * 100vw);
  }

  .nav_icon_container {
    margin-right: calc(20 / 414 * 100vw);
  }

  .small_dot {
    width: calc(6 / 414 * 100vw);
    height: calc(6 / 414 * 100vw);
    margin-top: calc(3 / 414 * 100vw);
  }

  .bell_container {
    margin-left: calc(10 / 414 * 100vw);
  }
  .logo {
    width: calc(35 / 414 * 100vw);
    height: calc(35 / 414 * 100vw);
    margin-right: calc(10 / 414 * 100vw);
  }
}
