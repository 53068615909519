.container {
  /* height: 100vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: calc(40 / 640 * 100vh);
}
.back_container {
  width: 63.91vw;
  cursor: pointer;
}
.sub_container,
.accessibility_sub_container {
  width: 63.91vw;
  display: flex;
  align-items: flex-end;
}
.user_container {
  width: 29.61vw;
  background: #228699;
  /* height: 109px;   */
  height: 21.1vh;
  padding: 0 1.641vw;
  border-radius: 0.78vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.name {
  text-transform: capitalize;
  font-family: Inter;
  font-weight: 600;
  font-size: 1.72vw;
  color: #fff;
}
.type {
  font-family: Inter;
  color: #fff;
  font-size: 1.1vw;
  margin-top: 0.635vh;
}
.heading {
  font-weight: 600;
  font-family: Inter;
  font-size: 1.563vw;
  margin-top: 2.5vh;
  margin-bottom: 2.5vh;
}
.nav_container {
  width: 29.61vw;
  height: 10.94vh;
  border-radius: 0.78vw;
  display: flex;
  align-items: center;
  padding: 0 1.641vw;
  margin-bottom: 0.94vh;
  cursor: pointer;
  cursor: pointer;
  transition: all 0.2s;
}
.nav_container:hover {
  background: rgba(217, 217, 217, 0.23);
}
.nav_text {
  font-family: Inter;
  font-size: 1.25vw;
  color: #228699;
  margin-left: 1.25vw;
  margin-right: auto;
}
.second_section_container {
  margin-left: 4.7vw;
}
.checkbox_container {
  display: flex;
  margin-bottom: 20px;
  gap: 10px;
  align-items: center;
  width: 60%;
}
.accessibility_sub_container {
  margin-top: 30px;
  flex-direction: column;
  align-items: flex-start;
}

.checkbox {
  accent-color: #228699;
  width: 18px;
  height: 18;
  border-radius: 7px;
  vertical-align: middle;
  margin-left: auto;
  cursor: pointer;
}

@media screen and (max-width: 999px) {
  .container {
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 0px 10px;
    width: 100%;
    margin-top: 25px;
  }
  .back_container {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 10px;
  }
  .user_account_group {
    width: 100%;
  }
  .sub_container,
  .accessibility_sub_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .user_container {
    width: 100%;
    background: #228699;
    height: 109px;
    padding: 5px 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .name {
    font-size: 16px;
  }
  .type {
    font-family: Inter;
    font-size: 12px;
    margin-top: 4px;
  }
  .heading {
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
  }
  .nav_container {
    background: rgba(255, 255, 255, 0.18);
    width: 100%;
    height: 70px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 0 21px;
    margin-bottom: 6px;
    cursor: pointer;
  }
  .nav_text {
    font-family: Inter;
    font-size: 14px;
    color: #228699;
    margin-left: 16px;
    margin-right: auto;
  }
  .second_section_container {
    margin-left: 0;
    width: 100%;
  }
  .checkbox_container {
    margin-bottom: 20px;
    gap: 10px;
    width: 100%;
  }
  .accessibility_sub_container {
    margin-top: 0px;
  }

  .checkbox {
    width: 18px;
    height: 18;
    border-radius: 7px;
  }
}
@media screen and (max-width: 540px) {
  .container {
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 0px calc(10 / 414 * 100vw);
    width: 100%;
    margin-top: calc(25 / 896 * 100vh);
  }
  .back_container {
    width: 100%;
    margin-top: 0px;
    margin-bottom: calc(10 / 896 * 100vh);
  }
  .user_account_group {
    width: 100%;
  }
  .sub_container,
  .accessibility_sub_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .user_container {
    width: 100%;
    background: #228699;
    height: calc(109 / 896 * 100vh);
    padding: calc(55 / 896 * 100vh) calc(20 / 414 * 100vw);
    border-radius: calc(10 / 414 * 100vw);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .name {
    font-size: calc(16 / 414 * 100vw);
  }
  .type {
    font-family: Inter;
    font-size: calc(12 / 414 * 100vw);
    margin-top: calc(4 / 896 * 100vh);
  }
  .heading {
    font-size: calc(14 / 414 * 100vw);
    margin-top: calc(10 / 896 * 100vh);
    margin-bottom: calc(10 / 896 * 100vh);
    margin-left: calc(10 / 414 * 100vw);
  }
  .nav_container {
    background: rgba(255, 255, 255, 0.18);
    width: 100%;
    height: calc(70 / 896 * 100vh);
    border-radius: calc(10 / 414 * 100vw);
    display: flex;
    align-items: center;
    padding: 0 calc(21 / 414 * 100vw);
    margin-bottom: calc(6 / 896 * 100vh);
    cursor: pointer;
  }
  .nav_text {
    font-family: Inter;
    font-size: calc(14 / 414 * 100vw);
    color: #228699;
    margin-left: calc(16 / 414 * 100vw);
    margin-right: auto;
  }
  .second_section_container {
    margin-left: 0;
    width: 100%;
  }
  .checkbox_container {
    margin-bottom: calc(20 / 896 * 100vh);
    gap: calc(10 / 414 * 100vw);
    width: 100%;
  }
  .accessibility_sub_container {
    margin-top: 0px;
  }

  .checkbox {
    width: calc(18 / 414 * 100vw);
    border-radius: calc(7 / 414 * 100vw);
  }
}
