.container {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 900;
  flex-shrink: 0;
  background: rgba(34, 134, 153, 0.47);
  display: flex;
  align-items: center;
  justify-content: center;
}

.sub_container {
  width: 32.96875vw; /* 422px to vw */
  height: fit-content;
  flex-shrink: 0;
  border-radius: 0.78125vw; /* 10px to vw */
  background: #228699;
  padding: 2.5vw; /* 32px to vw */
}

.title {
  text-transform: capitalize;
  color: #fff;
  font-family: Inter;
  font-size: 1.5625vw; /* 20px to vw */
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.msg {
  width: 27.890625vw; /* 357px to vw */
  color: #d9d9d9;
  font-family: Inter;
  font-size: 0.9375vw; /* 12px to vw */
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 1.171875vw 0vw 2.1875vw 0vw; /* 15px 0px 28px 0px to vw */
}

.action_btn {
  width: 27.890625vw; /* 357px to vw */
  height: 4.453125vw; /* 57px to vw */
  flex-shrink: 0;
  border-radius: 1.5625vw; /* 20px to vw */
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #228699;
  text-align: center;
  font-family: Inter;
  font-size: 0.9375vw; /* 12px to vw */
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  transition: all 0.2s;
  margin-top: 1.71875vw; /* 13px to vw */
}

.action_btn:hover {
  background: #e6e3e3;
}

.close_btn {
  cursor: pointer;
  width: 27.890625vw; /* 357px to vw */
  height: 4.453125vw; /* 57px to vw */
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.5625vw; /* 20px to vw */
  border: 0.15625vw solid #fff; /* 2px to vw */
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 0.9375vw; /* 12px to vw */
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 1.71875vw; /* 13px to vw */
  background: rgba(34, 134, 153, 0.47);
  transition: all 0.2s;
}

.close_btn:hover {
  background: rgba(27, 107, 123, 0.47);
}

.input_container {
  width: 27.890625vw; /* 357px to vw */
  height: 4.453125vw; /* 57px to vw */
  display: flex;
  flex-shrink: 0;
  border-radius: 1.5625vw; /* 20px to vw */
  border: 0.15625vw solid #fff; /* 2px to vw */
  transition: all 0.2s;
  background: rgba(34, 134, 153, 0.47);
  margin-top: 1.71875vw; /* 13px to vw */
  padding: 0.78125vw; /* 10px to vw */
}

.input {
  color: #fff;
  font-family: Inter;
  font-size: 0.9375vw; /* 12px to vw */
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: none;
  padding: 0.78125vw; /* 10px to vw */
  outline: none;
  border: none;
  width: 100%;
}

.loader {
  margin-left: auto;
}

.enter_email_loader_con {
  display: flex;
  justify-content: center;
  margin-top: 1.71875vw; /* 13px to vw */
}

.enter_email_loader {
  align-items: center;
  text-align: center;
}
@media screen and (max-width: 999px) {
  .container {
    padding: 30px;
  }
  .sub_container {
    width: 100%;
    border-radius: 10px;
    padding: 40px 20px;
  }

  .title {
    font-size: 20px;
  }

  .msg {
    width: 100%;
    font-size: 14px;
    margin: 15px 0;
  }

  .action_btn {
    width: 100%;
    height: 57px;
    border-radius: 20px;
    font-size: 14px;
  }

  .close_btn {
    width: 100%;
    height: 57px;
    border-radius: 20px;
    border: 2px solid #fff; /* 2px / 1280 * 100 */
    font-size: 14px;
    margin-top: 13px;
  }

  .input_container {
    width: 100%;
    height: 57px;
    border-radius: 20px;
    border: 2px solid #fff;
    margin-top: 13px;
    padding: 10px;
  }

  .input {
    font-size: 12px;
    padding: 10px;
  }

  .enter_email_loader_con {
    margin-top: 13px;
  }
}
@media screen and (max-width: 540px) {
  .container {
    padding: calc(30 / 896 * 100vh) calc(30 / 414 * 100vw);
  }
  .sub_container {
    width: 100%;
    border-radius: calc(10 / 414 * 100vw);
    padding: calc(40 / 896 * 100vh) calc(20 / 414 * 100vw);
  }

  .title {
    font-size: calc(20 / 414 * 100vw);
  }

  .msg {
    width: 100%;
    font-size: calc(14 / 414 * 100vw);
    margin: calc(15 / 896 * 100vh) 0;
  }

  .action_btn {
    width: 100%;
    height: calc(57 / 896 * 100vh);
    border-radius: 20px;
    font-size: calc(14 / 414 * 100vw);
  }

  .close_btn {
    width: 100%;
    height: calc(57 / 896 * 100vh);
    border-radius: calc(20 / 414 * 100vw);
    border: calc(2 / 414 * 100vw) solid #fff; /* 2px / 1280 * 100 */
    font-size: calc(14 / 414 * 100vw);
    margin-top: 13px;
  }

  .input_container {
    width: 100%;
    height: calc(57 / 896 * 100vh);
    border-radius: calc(20 / 414 * 100vw);
    border: 2px solid #fff;
    margin-top: calc(13 / 896 * 100vh);
    padding: calc(10 / 896 * 100vh) calc(10 / 414 * 100vw);
  }

  .input {
    font-size: calc(12 / 414 * 100vw);
    padding: calc(10 / 896 * 100vh) calc(10 / 414 * 100vw);
  }

  .enter_email_loader_con {
    margin-top: calc(13 / 896 * 100vh);
  }
}
