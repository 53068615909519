ol, ul {
    list-style-position: outside;
    padding-left: 20px;
  }
  
/* ol li, ul li {
    margin-bottom: 10px;
} */

ul{
    list-style: disc;
}

ol{
    list-style: decimal;
}